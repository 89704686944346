/* eslint-disable no-irregular-whitespace */
<template>
  <ContentHero>
    <div class="row">
      <div class="col-lg-12">
        <h1 class="display-4">
          Credits.
          <span> Thank you! </span>
        </h1>
      </div>
    </div>
  </ContentHero>
  <div class="container terms">
    <h2 id="we-wish-to-thank-you-to-the-projects-that-coingrig-uses-">We wish to thank you to the projects that Coingrig uses:</h2>
    <ul>
      <li>www.coingecko.com</li>
      <li>Illustrations <a href="http://www.freepik.com">Designed by pch.vector / Freepik</a></li>
      <li>Illustration by Victoria Chepkasova from <a href="https://icons8.com/illustrations/">Ouch!</a></li>
      <li>Illustration by <a href="https://iconscout.com/contributors/delesign">Delesign Graphics</a></li>
      <li>Illustration by Icons 8 from <a href="https://icons8.com/illustrations/">Ouch!</a></li>
      <li>Illustration by Natasha Remarchuk from <a href="https://icons8.com/illustrations/">Ouch!</a></li>
    </ul>    
  </div>
  <!-- <div class="container terms">
    <h2 id="Licenses">And all the other code libraries developers:</h2>
    <div v-html="source">
    </div>
  </div> -->
</template>

<script>
import ContentHero from "@/components/ContentHero";
export default {
  name: "Credits",
  components: {
    ContentHero,
  },
  data: () => {
    return {
      source: '',
    };
  },
  created () {
    // this.fetchData()
  },
  methods: {
    fetchData () {
      // var client = new XMLHttpRequest();
      // client.open('GET', '/assets/licenses.txt');
      // client.onreadystatechange = () => {
      //   console.log(client.responseText)
      //   this.source = client.responseText;
      // }
      // client.send();
    }
  }
};
</script>

<style lang="scss">
.terms {
  text-align: justify;
  text-justify: inter-word;

  .h1,
  h1 {
    font-size: 1.75rem;
    color: $primary;
  }
  .h2,
  h2 {
    font-size: 1.4rem;
    font-weight: 400 !important;
  }
  .h3,
  h3 {
    font-size: 1rem;
    font-weight: 400 !important;
  }
}
</style>
