<template>
  <div class="container-fluid content-hero">
    <div class="container py-5">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentHero",
  props: {
    msg: String,
  },
};
</script>

<style lang="scss">
.content-hero {
  margin: 0;
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  overflow: hidden;

  background-image: linear-gradient(
    147deg,
    rgba(141, 141, 236, 0.17) 0%,
    rgba(84, 84, 212, 0) 100%
  );

  .display-4 span {
    display: block;
  }
}
</style>
