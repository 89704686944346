<template>
  <div class="landing">
    <DiagonalSection>
      <div class="container shape-container d-flex">
        <div class="col px-0 margin-top">
          <div class="row pt-1 py-lg-0  px-lg-5">
            <div class="col-lg-7 col-12 d-flex align-items-center py-1 py-lg-0 ">
              <div>
                <p>&nbsp;</p>
                <h1 class="display-4 fw-bold mb-3">
                  Next-Gen<br />Crypto Wallet
                </h1>
                <p class="mb-5 fs-2 fw-light">
                  A powerful crypto wallet for everyone.
                  <br />
                  Private, secure and open source.
                </p>
                <div class="row mb-5">
                  <div class="col align-items-left">
                    <a role="button" href="https://apps.apple.com/us/app/coingrig-crypto-btc-wallet/id1583464451">
                      <img
                        src="../assets/download_apple.png"
                        height="45"
                        alt="Apple store"
                      />
                    </a>
                    &nbsp;
                    <a role="button" href="https://play.google.com/store/apps/details?id=com.coingrig">
                      <img src="../assets/download_play.png"
                        height="45"
                        alt="Google store"
                      />
                    </a>
                  </div>                
                </div>
              </div>
            </div>
            <div
              class="col-lg-5 img-fluid pb-5 pt-3 d-flex align-items-center justify-content-center"
            >
              <picture>
                <source type="image/webp" alt="Mobile app" srcset="../assets/web_1.webp,  ../assets/web_1_2x.webp 2x" width="499" height="692">
                <source type="image/png" alt="Mobile app" srcset="../assets/web_1.png,  ../assets/web_1_2x.png 2x" width="499" height="692">
                <img src="../assets/web_1.png" alt="Mobile app" class="img-fluid" width="499" height="692">
              </picture>
            </div>
          </div>
        </div>
      </div>
    </DiagonalSection>

    <div class="container top-challenge">
      <div class="row mt-4 text-left text-lg-center">
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-12 col-lg-6 h5">
              <i class="fa fa-stop landing-bullet landing-bullet-1"></i> Buy crypto with card
            </div>
            <div class="col-12 col-lg-6 h5">
              <i class="fa fa-stop landing-bullet landing-bullet-2"></i> 1M+ tokens
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-12 col-lg-6 h5">
              <i class="fa fa-stop landing-bullet landing-bullet-3"></i> Unified Swap
            </div>
            <div class="col-12 col-lg-6 h5">
              <i class="fa fa-stop landing-bullet landing-bullet-4"></i> Private & Secure
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="container-fluid gray-bg margin-top">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-4">
            <h1 class="display-6 fw-normal">Our mission is to make your life easier.</h1>
          </div>
          <div class="col-lg-8 fs-5 fw-light">
            With planned features for both mainstream and exotic currencies
            Coingrig Wallet aims to be an easy to use app that reduces the
            complexity of crypto currency access by reducing the number of steps
            required to access the multitude of blockchains currently existing.
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid blue">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-6 text-left">
            <div class="row">
              <div class="col-lg-6 text-left">
                <LandingCard class="mx-4">
                  <template v-slot:icon><i class="fas fa-coins"></i></template>
                  <p class="text-landing-card text-uppercase">Manage Tokens</p>
                  <p class="description mt-3">
                    Swap hundreds of tokens across multiple blockchains from a single wallet.
                  </p>
                </LandingCard>
              </div>
              <div class="col-lg-6 text-left">
                <LandingCard class="mx-4">
                  <template v-slot:icon>
                    <i class="fas fa-vector-square"></i>
                  </template>
                  <p class="text-landing-card text-uppercase">Manage NFTs</p>
                  <p class="description mt-3">
                    Manage your NFT collection and trade NFTs with ease directly from your wallet.
                  </p>
                </LandingCard>
              </div>
            </div>
          </div>
          <div class="col-lg-6 px-3 my-5">
            <h2>With more features coming soon!</h2>
            A powerful crypto wallet for everyone. Unique features and open source.
          </div>
        </div>
      </div>
    </div> -->
<div class="container px-lg-5">
    <AllChains class="mt-5"/>
</div>
<div class="container px-lg-5">
    <Dex class="mt-5"/>
</div>
    <div class="container py-5 pt-5 pb-2 mt-3 mb-0 text-center" style="max-width: 800px;">
      <div class="row h4 fw-light">
        <p>Coingrig is your easy-to-use day-to-day crypto wallet for Bitcoin, Ethereum, Binance Smart Chain, Polygon and 1M+ of crypto assets.</p>
      </div>
    </div>  
<div class="container px-lg-5">
    <InfoSection />
</div>

    <div class="container-fluid gray-bg">
      <div class="container py-5 my-5">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h1 class="display-6 fw-normal mb-4">The future is based on Crypto.</h1>
            <a class="btn btn-dark " aria-current="vision" href="/vision">
              Read about our vision
            </a>
          </div>        
        </div>
      </div>
    </div>
<div class="container px-lg-5">
    <AllFeatures />
</div>
      <div class="container-fluid gray-bg margin-top">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="display-6 fw-normal text-center">Why do you need a private wallet.</h1>
          </div>        
        </div>
      </div>
    </div>

    <div class="container py-5 my-5 px-lg-5">
      <div class="row mb-5">
        <div class="col-lg-4">
          <p class="h2 fw-light">Private, Non-custodial.</p>
        </div>
        <div class="col-lg-8 fs-5 fw-light">
          As a private wallet, Coingrig Wallet ensures you always hold the keys to
          your crypto currencies and can always send and receive crypto with
          Coingrig and any other blockchain compatible apps.
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-4">
          <p class="h2 fw-light">Open source.</p>
        </div>
        <div class="col-lg-8 fs-5 fw-light">
          Being open source the Coingrig Wallet can be inspected by the community
          to ensure that no security issues can expose your private keys to your
          crypto currency.
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <p class="h2 fw-light">A Wallet for Everyone.</p>
        </div>
        <div class="col-lg-8 fs-5 fw-light">
          With a simple layout Coingrig Wallet provides an easy to use interface
          to the day to day needs for cryptocurrency.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiagonalSection from '../components/DiagonalSection.vue'
import AllFeatures from '../components/AllFeatures.vue'
import AllChains from '../components/AllChains.vue'
import Dex from '../components/Dex.vue'
import InfoSection from '../components/InfoSection.vue'

export default {
  name: 'Landing',
  components: {
    DiagonalSection,
    AllFeatures,
    AllChains,
    Dex,
    InfoSection
  },
}
</script>

<style lang="scss">
.landing {
  color: #353333;
}
.landing-ball {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: #353333;
  align-self: center;
  justify-content: center;
}

.blue {
  background-color: rgb(233, 233, 252);
}
.margin-top {
  margin-top: 50px;
}

.chain-box {
  box-sizing: border-box;
  min-width: 0px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border: 1px solid rgba(65, 62, 101, 0.114);
  border-radius: 10px;  

  .rounded-icon {
    width: 3rem;
    height: 3rem;
    text-align: center;
    border-radius: 50%!important;
    background-color: $primary-100;  
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.maxw {
  max-width: 1200px;
}

.gray-bg {
  background-image: linear-gradient(
    147deg,
    rgba(141, 141, 236, 0.17) 0%,
    rgba(84, 84, 212, 0) 100%
  );
}

.landing-bullet {
  font-size: 1rem;  
  margin-bottom: 2px;
}

.landing-bullet-2 {
  color: $primary !important;
}

.landing-bullet-3 {
  color: $gray-600 !important;
}

.landing-bullet-4 {
  color: $blue !important;
}

</style>
