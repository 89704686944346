<template>
  <footer class="footer">
    <FooterTop />
    <FooterBottom />
  </footer>
  <div class="container-fluid footer-copyright text-center">
    &copy; {{ year }} Coingrig
  </div>
</template>
<script>

import FooterTop from './Footer/FooterTop.vue';
import FooterBottom from './Footer/FooterBottom.vue';

export default {
  name: "app-footer",
  components: {
    FooterBottom, FooterTop
  },
  data() {
    return {
      //href="mailto:contact@coingrig.com"
      year: new Date().getFullYear(),
    };
  },
  methods: {    
  }
};
</script>
<style lang="scss">
.footer {
  background-color: #eeeefd;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 30px;
  margin-top: 100px;
}

.footer-copyright {
  background: #353535;
  color: #f4f5f7;
  padding: 10px;
}
</style>

