<template>
  <div class="container-fluid my-5">
    <div class="container py-5 my-5">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-lg-6 text-center">
          <picture >
              <source type="image/webp" alt="Mobile app features: Buy, Send and Receive Crypto" srcset="../assets/web_5.webp, ../assets/web_5_2x.webp 2x" width="477" height="480">
              <source type="image/png" alt="Mobile app features:Buy, Send and Receive Crypto" srcset="../assets/web_5.png, ../assets/web_5_2x.png 2x" width="477" height="480">
              <img src="../assets/web_5.png" alt="Mobile app features: Buy, Send and Receive Crypto" class="info-img-fluid-landscape" width="477" height="480">
          </picture>
        </div>
        <div class="col-lg-6 my-auto pl-lg-4" >
          <h2 class="mb-3">
            <span class="fs-4"><i class="fa fa-stop info-bullet-black"></i></span> Buy, Send and Receive
          </h2>
          <p class="fs-5 fw-light">
            Transact popular Coins and 1M+ of ERC20 tokens directly from the app or connect through WalletConnect to your preferred DEX. <br />
            Buy crypto with your credit card or wire transfer.
          </p>          
          <p class="text-left">
            <img src="../assets/buy.png" alt="" class="img-fluid mt-2"  width="400"> <br />
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid my-5">
    <div class="container py-5 my-5">
      <div class="row">
        <div class="col-lg-7 my-auto px-lg-5">
          <h2 class="mb-3">
            <span class="fs-4"><i class="fa fa-stop info-bullet-orange"></i></span> Track your assets
          </h2>
          <p class="fs-5 fw-light">
            You can quickly track the most popular tokens and add your manually added custom ERC20 tokens. <br />
            The keys to your assets stay safe on your mobile phone.
          </p>
        </div>
        <div class="col-lg-5 text-center">
          <picture >
              <source type="image/webp" alt="Mobile app features: track 1M+ of the most popular tokens and add your custom ERC20 tokens manually" srcset="../assets/web_2.webp, ../assets/web_2_2x.webp 2x" width="287" height="580">
              <source type="image/png" alt="Mobile app features: track 1M+ of the most popular tokens and add your custom ERC20 tokens manually" srcset="../assets/web_2.png, ../assets/web_2_2x.png 2x" width="287" height="580">
              <img src="../assets/web_2.png" alt="Mobile app features: track 1M+ of the most popular tokens and add your custom ERC20 tokens manually" class="info-img-fluid" width="287" height="580">
          </picture>
        </div>        
      </div>
    </div>
  </div>

  <div class="container-fluid my-5">
    <div class="container py-5 my-5">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-lg-6 text-center">
          <picture >
              <source type="image/webp" alt="Mobile app features: news, market prices, transactions" srcset="../assets/web_3.webp, ../assets/web_3_2x.webp 2x" width="419" height="580">
              <source type="image/png" alt="Mobile app features: news, market prices, transactions" srcset="../assets/web_3.png, ../assets/web_3_2x.png 2x" width="419" height="580">
              <img src="../assets/web_3.png" alt="Mobile app features: news, market prices, transactions" class="info-img-fluid-landscape-last" width="419" height="580">
          </picture>
        </div>
        <div class="col-lg-6 my-auto">
          <h2 class="mb-3">
            <span class="fs-4"><i class="fa fa-stop info-bullet-gray"></i></span> Market information
          </h2>
          <p class="fs-5 fw-light">
            Quickly monitor the market trends on the top traded assets, top gainers and losers. <br />
            Browse the latest Crypto news from the market. <br />
            See general information and the last 7 days trend of your asset of interest.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.info-img-fluid {
  max-height: 580px;
}
.info-img-fluid-landscape {
  max-height: 480px;
  max-width: 100%;
  width: auto;
  height: auto;
}
.info-img-fluid-landscape-last {
  max-height: 580px;
  max-width: 100%;
  height: auto;
}
.info-bullet-orange {
  color: $primary;
}
.info-bullet-gray {
  color: $gray-600;
}
</style>
