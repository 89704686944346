<template>
  <nav
    class="navbar navbar-main navbar-transparent navbar-expand-lg navbar-light px-sm-5"
  >
    <a class="navbar-brand ml-5" href="/">
      <img
        src="/assets/logo.png"
        class="img-fluid"
        alt="Logo"
        width="50"
        height="50"
      />
      Coingrig
    </a>
    <button
      class="navbar-toggler"
      style="margin-right: 10px"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      </ul>
      <ul class="d-flex navbar-nav mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="home" href="/">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="vision" href="/vision">Vision</a>          
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="docs" rel="noopener" target="_blank" href="https://docs.coingrig.com">Docs</a>          
        </li>
        <!-- <li class="nav-item">
              <a class="nav-link" href="#">About</a>
            </li> -->
        <li class="nav-item">
            <a
            target="_blank"
            rel="noopener"
            href="https://twitter.com/coingrig"
            class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Follow us"
            aria-label="Follow on Twitter"
          >
            <i class="fab fa-twitter"></i>
          </a>  
          <a
            target="_blank"
            rel="noopener"
            href="https://github.com/coingrig"
            class="btn btn-neutral btn-icon-only btn-github btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Star on Github"
            aria-label="Star on Github"
          >
            <i class="fab fa-github"></i>
          </a>    
        </li>   
      </ul>
    </div>
  </nav>
</template>

<style lang="scss">
.navbar-transparent {
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent !important;
  border: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.nav-item {
   text-align: right;
}

.horizontal-padding {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navbar {
  .btn-twitter, .btn-twitter:hover {
    color: $twitter;
    background-color: transparent;
    border: none;
  }

  .btn-github, .btn-github:hover {
    color: $github;
    background-color: transparent;
    border: none;
  }

  a.btn-icon-only {    
    font-size: 1.3rem;
    width: 2.375rem;
    height: 2.375rem;
    padding: 0;
    line-height: 2;
  }
}
</style>
