<template>
  <div class="container-fluid">
    <div class="container py-4 px-lg-5">

    <div class="justify-content-center row">
      <div class="col-lg-9 mb-5">
        <div class="text-center">
          <p color="heading" class="h2 display-6 fw-bold">
            Unified Swap
          </p>
          <p class="fs-5 fw-light">
            Swap any assets you want, directly integrated with more than 50 Liquidity Sources.
          </p>
        </div>
      </div>

      <div class="justify-content-center row">
       <div class="col-lg-8 text-center">
          <picture >
              <img src="../assets/dex2.png" alt="Mobile app features: Buy, Send and Receive Crypto" class="info-img-fluid-landscape" width="477" height="480">
          </picture>
        </div>      
      </div>
    </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Dex',
  components: {},
  data: function () {
    return {
      rows: [
        [{
          'image': 'chains/bitcoin.webp',
          'text': 'Bitcoin',
        },{
          'image': 'chains/ethereum.webp',
          'text': 'Ethereum',
        },{
          'image': 'chains/binance-coin-logo.webp',
          'text': 'Binance Smart Chain',
        }],
        [{
          'image': 'chains/matic-token-icon.webp',
          'text': 'Polygon (MATIC)',
        },{
          'icon': 'fas fa-project-diagram',
          'text': 'More available soon',
        }]        
      ]
    }
  },
  methods: {
    getImgUrl(icon) {
      return '/assets/' + icon;
    }
  }
}
</script>

<style lang="scss">
.chains-box {
  box-sizing: border-box;
  min-width: 0px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border: 1px solid rgba(65, 62, 101, 0.114);
  border-radius: 10px;  

  .chain-icon {
    height: 30px;
  }

  .rounded-icon {
    width: 3rem;
    height: 3rem;
    text-align: center;
    border-radius: 50%!important;
    background-color: #f5f4f4;  
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>