<template>
  <ContentHero>
    <div class="row">
      <div class="col-lg-12">
        <h1 class="display-4">
          Privacy Policy
          <span> last revised: 30 August, 2021 </span>
        </h1>
      </div>
    </div>
  </ContentHero>
  <div class="container terms">
    <p>This Privacy Policy describes the policies and procedures of Qubevo Sourcing SRL  (“we,” “our,” or “us”) pertaining to the collection, use, and disclosure of your information on www.coingrig.com and related mobile applications and products we offer (the “Services” or “Coingrig Wallet”).</p>
    <h2 id="overview">OVERVIEW</h2>
    <p>Your privacy is important to us. We don’t ask you for personally identifiable information (defined below). That being said, your contact information, such as your phone number, social media handle, or email address (depending on how you contact us), may be collected when you communicate with us or if you report a bug or other error related to Coingrig Wallet. We don’t share your information with third parties except to deliver you our Services and products, comply with the law, make Coingrig Wallet better, protect our rights, or effectuate a business transfer.</p>
    <p>Our Services are hosted and offered from within the European Union so if you are located in a country that falls under the scope of the GDPR, data protection laws give you certain rights with respect to your personal data, subject to any expectations provided by the law.</p>
    <p>If you have any questions or concerns about this policy, please reach out to us at support@coingrig.com.</p>
    <h2 id="how-you-accept-this-policy">HOW YOU ACCEPT THIS POLICY</h2>
    <p>By using Coingrig Wallet, including downloading one of our mobile applications and visiting our website, you agree to the use, disclosure, and procedures outlined in this Privacy Policy.</p>
    <h2 id="what-data-do-we-collect-">What data do we collect?</h2>
    <p>The information we collect from you falls into two categories: (i) personally identifiable information (i.e., data that could potentially identify you as an individual) (“Personal Information”), and (ii) non-personally identifiable information (i.e., information that cannot be used to identify who you are) (“Non-Personal Information”). This Privacy Policy covers both categories and will tell you how we might collect and use each type. We do our best not to collect any Personal Information from Coingrig Wallet users. That being said, when using our Services, we do collect PUBLIC wallet addresses that you generate through Coingrig Wallet. Further, we may collect some Personal Information from you when you communicate with us, including your contact information, such as your phone number, social media handle, or email address (depending on how you reach out). Like other online services, we also collect a variety of Non-Personal Information, including: Information you create through the Coingrig Wallet’s website or mobile applications, including public wallet addresses. Various analytics data, such as: (i) the IP address of the computer you use to access Coingrig Wallet; (ii) the type of browser software you are using; (iii) the operating system you are using; (iv) the date and time you access or use Coingrig Wallet; (v) the website address, if any, that linked you to Coingrig Wallet; (vi) the website address, if any, you leave our website and travel to; and (vii) other non-personally identifiable traffic data.</p>
    <h3 id="cookies">Cookies</h3>
    <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology</p>
    <p>For further information, visit allaboutcookies.org.</p>
    <p>How do we use cookies?</p>
    <p>Our Company uses cookies in a range of ways to improve your experience on our website, including:</p>
    <p>What types of cookies do we use?</p>
    <p>There are a number of different types of cookies, however, our website uses:</p>
    <ul>
    <li>Advertising – Our Company uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Our Company sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</li>
    </ul>
    <p>How to manage cookies</p>
    <p>You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>
    <h2 id="privacy-policies-of-other-websites">Privacy policies of other websites</h2>
    <p>The Our Company website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>
    <h2 id="how-do-we-collect-your-data-">How do we collect your data?</h2>
    <p>When You Contact Us. We may collect certain information if you choose to contact us, if you use our Services or if you report a bug or other error with Coingrig Wallet. This may include contact information such as your name, email address, phone number, and public wallet address. We, or companies that provide services on our behalf, may also collect certain Non-Personal Information from you, such as your locally hosted public wallet (a “Wallet”) addresses.</p>
    <h2 id="information-we-automatically-collect">Information We Automatically Collect</h2>
    <p>Users who visit our website or use our application may have their device’s IP address logged for the purpose of generating anonymous statistics or troubleshooting the performance of our web servers. Your IP address will not be used to track or identify you, but may be used to determine your geographic location in order to determine which of our services you are presented with. Users of our website or mobile applications will receive an anonymous unique device id (“UDID”) for the purpose of identifying the device to Coingrig Wallet servers. This UDID will not be tied to users’ identities, but will be used for debugging purposes and to differentiate devices when users access our Services using multiple devices.</p>
    <h2 id="third-party-services">Third Party Services</h2>
    <p>Certain features on Coingrig Wallet rely on various third-party products and services (collectively “Third Party Services”), such as the Ethereum network, Bitcoin network and other blockchain based networks, Google Analytics, Apple’s application platform, Tatum, Coingeko, Cloudflare and Trezor. These services may collect certain Personal Information, such as your public Wallet addresses. Coingrig Wallet uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google uses cookies to help the website analyze how users use our website. The information generated by the cookie about your use of our website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. You may choose to accept the cookies by selecting the appropriate settings on your browser if you do this you may not be able to use the full functionality of our website. By using our website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. Please note that your use of these Third Party Services is governed by their respective Terms of Service and Privacy Policies. We use and disclose any collected information in accordance with our own Privacy Policy.</p>
    <h2 id="how-will-we-use-your-data-">How will we use your data?</h2>
    <p>We primarily use the limited information we collect to enhance Coingrig Wallet. Except if we sell all or a portion of our business, or as otherwise described below, we do not rent, trade, or sell your Personal Information. Use of Information to Provide Coingrig Wallet to You Some ways we may use your Personal Information are to: Contact you when necessary; Respond to your comments, questions, or issues related to bugs or errors with Coingrig Wallet; Provide you with additional information; Send you information and marketing materials about services and products available through Coingrig Wallet, using push notifications or other means; Train our team members; or Other internal business purposes. Aggregated Personal Data and Non-Personal Information We may share or disclose aggregated Personal Data or Non-Personal Information with service providers or with other persons we conduct business with, including but not limited potential third-parties for the purpose of showcasing the performance of the company. These service providers and other persons may also share with us aggregated Non-Personal Information that they have independently developed or acquired. Additionally, we may combine aggregate information from the pixel tags, web beacons, and cookies with similar data we collect from other visitors to help us improve our Services. When doing so, we do our best to ensure that any aggregated information cannot be linked back to you.</p>
    <h2 id="marketing">Marketing</h2>
    <p>Our Company would like to send you information about products and services of ours that we think you might like, as well as those of our partner companies.</p>
    <p>If you have agreed to receive marketing, you may always opt out at a later date.</p>
    <p>You have the right at any time to stop Our Company from contacting you for marketing purposes or giving your data to other members of the Our Company Group.</p>
    <p>If you no longer wish to be contacted for marketing purposes, please update your preferences from within the Coingrig Wallet.</p>
    <h2 id="agents-or-third-party-partners">Agents or Third Party Partners</h2>
    <p>We may provide your Personal Information to our employees, contractors, agents, service providers, and designees (“Agents”) to enable them to perform certain services for us exclusively, including: Improvement of website-related services and features; and Perform maintenance services. Business Transfers We may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that would be transferred. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, your Personal Information could be one of the assets transferred to or acquired by a third party. By accepting this Privacy Policy, as outlined above, you consent to any such transfer.</p>
    <h2 id="protection-of-us-and-others">Protection of Us and Others</h2>
    <p>We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to: comply with the law or a court order; cooperate with law enforcement; enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety of Coingrig Wallet, our employees, our users, or others.</p>
    <h2 id="privacy-policies-of-other-websites">Privacy policies of other websites</h2>
    <p>The Our Company website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>
    <h2 id="how-do-we-store-your-data-">How do we store your data?</h2>
    <p>Most of the data is stored only on the user’s mobile device and is private to the user only accessible through the use of the mobile device where the Coingrig application is running.</p>
    <p>Information that is stored in the 3rd party blockchain networks is decentralised and public and is not a service provided by us.</p>
    <p>Only contact information if provided by the user might be stored in 3rd party help desk type support systems or email services.</p>
    <p>Our Company will keep your contact data received by directly contacting our company for 1 year. Once this time period has expired, we will delete your data by deleting the emails or tickets from the help-desk ticketing system.</p>
    <p>Contact data published on public 3rd party services like Github is not stored by our company.</p>
    <h2 id="what-personal-information-can-i-access-or-change-">WHAT PERSONAL INFORMATION CAN I ACCESS OR CHANGE?</h2>
    <p>We do not have access to private information such as the Wallet’s private key or the wallet generation seed (“Mnemonic”). This is stored on the mobile device where our app is running and it is the user’s responsibility to keep a back-up.</p>
    <p>As a user you must understand that we can not help you recover any of this sensitive information and if lost you must understand that the funds held within the respective wallets would be considered lost forever as they would become inaccessible.</p>
    <p>Information recorded within the blockchain networks can not be deleted and is publicly accessible but changes can be operated only based on the Wallet secret keys.</p>
    <p>Upon deletion of the mobile app the user can re-activate his access to the blockchain networks using the wallet generation seed and regain access to his wallets. The user may at any time use other 3rd party applications to access the blockchain networks based on his wallet generation seed.</p>
    <p>You can request access to the information we have collected from you. You can do this by contacting us at support@coingrig.com. We will make sure to provide you with a copy of the data we process about you. To comply with your request, we may ask you to verify your identity. We will fulfill your request by sending your copy electronically. For any subsequent access request, we may charge you with an administrative fee. If you believe that the information we have collected is incorrect, you are welcome to contact us so we can update it and keep your data accurate. Any data that is no longer needed for purposes specified in the “How We Use the Information We Gather” section will be deleted after ninety (90) days. Wallet addresses created through the Coingrig Wallet application cannot be deleted from the Ethereum blockchain or other such blockchain based networks, therefore we are unable to delete this personal information. If at any point you wish for Coingrig Wallet to delete information about you, you may contact us at support@coingrig.com.</p>
    <h2 id="data-retention">DATA RETENTION</h2>
    <p>If you delete your Wallet or addresses from the Coingrig Wallet Android mobile application, uninstall Coingrig Wallet mobile applications from your device, or request that your information be deleted, we still may retain some information that you have provided to us to maintain Coingrig Wallet or to comply with relevant laws.</p>
    <h2 id="data-security">DATA SECURITY</h2>
    <p>We are committed to making sure your information is protected and have selected third-party vendors which use the Ethereum network, Bitcoin network and other blockchain based networks, Google Analytics, Apple’s application platform, Tatum, Coingeko, Cloudflare and Trezor that help keep your Personal Information safe. Unfortunately, we do not control these third parties and therefore cannot guarantee complete security. We employ several physical and electronic safeguards to keep your information safe, including encrypted user passwords, two factor verification and authentication on passwords where possible, and securing all connections with industry standard transport layer security. Even with all these precautions, we cannot fully guarantee against the access, disclosure, alteration, or deletion of data through events, including but not limited to hardware or software failure or unauthorized use. Any information that you provide to us is done so entirely at your own risk.</p>
    <h2 id="what-are-your-data-protection-rights-under-gdpr-">What are your data protection rights under GDPR?</h2>
    <p>Our Company would like to make sure you are fully aware of all of your data protection rights under the scope of the EUropean General Data Protection Regulation (GDPR). Every European citizen user is entitled to the following:</p>
    <p>The right to access – You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.</p>
    <p>The right to rectification – You have the right to request that Our Company correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.</p>
    <p>The right to erasure – You have the right to request that Our Company erase your personal data, under certain conditions.</p>
    <p>The right to restrict processing – You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.</p>
    <p>The right to object to processing – You have the right to object to Our Company’s processing of your personal data, under certain conditions.</p>
    <p>The right to data portability – You have the right to request that Our Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: support@coingrig.com</p>
    <h2 id="children">CHILDREN</h2>
    <p>We are especially sensitive about children’s information. Our Services are not targeted towards children, and we don’t knowingly collect information from children under the age of 18. If you are a parent or legal guardian of a minor child, we will treat any information that you provide us while using Coingrig Wallet on behalf of your minor child as Personal Information as otherwise provided in this Privacy Policy. If you have questions concerning our information practices with respect to children, or if you learn that a child under the age of 18 has used Coingrig Wallet, created a user account, or provided us with personal information, please email us at support@coingrig.com</p>
    <h2 id="online-tracking-and-how-we-respond-to-do-not-track-signals">ONLINE TRACKING AND HOW WE RESPOND TO DO NOT TRACK SIGNALS</h2>
    <p>Online tracking is the collection of data about an individual’s Internet activity used to deliver targeted advertisements and for other purposes. Some web browsers (including Safari, Internet Explorer, Firefox, and Chrome) incorporate a “Do Not Track” (DNT) or similar feature that signals to websites that a visitor does not want to have his/her online activity and behavior tracked. If an online service elects to respond to a particular DNT signal, the service may refrain from collecting certain personal information about the browser’s user. Not all browsers offer a DNT option and there is currently no industry consensus as to what constitutes a DNT signal. For these reasons, many website operators, including Coingrig Wallet, do not take action to respond to DNT signals. For more information about DNT signals, visit <a href="http://allaboutdnt.com">http://allaboutdnt.com</a>.</p>
    <h2 id="changes-and-updates-to-privacy-policy">CHANGES AND UPDATES TO PRIVACY POLICY</h2>
    <p>We reserve the right to update and revise this privacy policy at any time. We occasionally review this Privacy Policy to make sure it complies with applicable laws and conforms to changes in our business. We may need to update this Privacy Policy, and we reserve the right to do so at any time. If we do revise this Privacy Policy, we will update the “Effective Date” at the bottom of this page so that you can tell if it has changed since your last visit and will do our best to notify you. Please review this Privacy Policy regularly to ensure that you are aware of its terms. Any use of Coingrig Wallet after an amendment to our Privacy Policy constitutes your acceptance to the revised or amended agreement.</p>
    <h2 id="non-eu-users-and-visitors">NON-EU USERS AND VISITORS</h2>
    <p>Coingrig Wallet is hosted in the European Union. If you are a user accessing the Services from the United States, Asia, or any other region with laws or regulations governing personal data collection, use, and disclosure that differ from European Union laws, please be advised that through your continued use of the Services, which is governed by EU law, you are transferring your Personal Information to the European Union and you consent to that transfer.</p>
    <h2 id="changes-to-our-privacy-policy">Changes to our privacy policy</h2>
    <p>Our Company keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 30 August 2021.</p>
    <h2 id="how-to-contact-us">How to contact us</h2>
    <p>If you have any questions about Our Company’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>
    <p>Email us at: support@coingrig.com</p>

  </div>
</template>

<script>
import ContentHero from "@/components/ContentHero";

export default {
  name: "PrivacyPolicy",
  components: {
    ContentHero
  },
  data: () => {
    return {

    };
  },
};
</script>

<style lang="scss">
.terms {
  text-align: justify;
  text-justify: inter-word;
  
  .h1, h1 {
    font-size: 1.75rem;
    color: $primary;
    text-transform: capitalize;
  }
  .h2, h2 {
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 400 !important;
  }
  .h3, h3 {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 400 !important;
  }
}
</style>
