<template>
  <div class="container container-lg footer-bottom">
    <div class="row">
      <div class="col-lg-3">
          <div class="footer-item mt-0 mx-4 mx-sm-0">
            <div class="footer-list contact-list">
              <div class="title">Contact Us</div>
              <div class="footer-social-link">
                <a
                  target="_blank"
                  rel="noopener"
                  href="#"
                  @click="contact_us"
                  class="btn btn-neutral btn-icon-only btn-email btn-round btn-lg"
                  data-toggle="tooltip"
                  data-original-title="Email us"
                  aria-label="Email us"
                >
                  <i class="fas fa-envelope"></i>
                </a> 
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://twitter.com/coingrig"
                  class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg"
                  data-toggle="tooltip"
                  data-original-title="Follow us"
                  aria-label="Follow on Twitter"
                >
                  <i class="fab fa-twitter"></i>
                </a>          
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://github.com/coingrig"
                  class="btn btn-neutral btn-icon-only btn-github btn-round btn-lg"
                  data-toggle="tooltip"
                  data-original-title="Star on Github"
                  aria-label="Star on Github"
                >
                  <i class="fab fa-github"></i>
                </a>
              </div>
            </div>
          </div>
      </div>
      <div class="col-lg-9">
          <div class="footer-item-wrap row">
            <div class="col-lg-3">
                <div class="footer-item mx-4 mx-sm-0">
                  <div class="footer-list">
                      <div class="title">Company</div>
                      <ul>
                        <li><a href="/vision" class="">Our vision</a></li>
                      </ul>
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="footer-item mx-4 mx-sm-0">
                  <div class="footer-list">
                      <div class="title">Resources</div>
                      <ul>
                        <li><a href="https://docs.coingrig.com/" rel="noopener" target="_blank">Docs</a></li>                        
                        <li><a href="https://docs.coingrig.com/use-cases/for-developers" rel="noopener" target="_blank">Developers</a></li>
                      </ul>
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="footer-item mx-4 mx-sm-0">
                  <div class="footer-list">
                      <div class="title">Legal</div>
                      <ul>
                        <li><a href="/terms">Term &amp; Condition</a></li>
                        <li><a href="/privacy">Privacy &amp; Policy</a></li>
                      </ul>
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="footer-item mx-4 mx-sm-0">
                  <div class="footer-list">
                      <div class="title">Coingrig Wallet</div>
                      <ul>
                        <li><a href="https://github.com/orgs/coingrig/projects/1" rel="noopener" target="_blank">Roadmap</a></li>
                        <li><a href="https://github.com/coingrig/coingrig-wallet/discussions" rel="noopener" target="_blank">Support</a></li>                        
                      </ul>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-footer-bottom",  
  data() {
    return {
      decoded: Buffer.from("Y29udGFjdEBjb2luZ3JpZy5jb20=", 'base64'), // btoa("email")
      prefix: Buffer.from("bWFpbHRvOg==", 'base64'), // btoa("mailto:")
    };
  },
  methods: {
    contact_us() {
      window.open(this.prefix + this.decoded, '_blank');
    }
  }  
};
</script>

<style lang="scss">
.footer-bottom {
  padding-top: 40px;
 
 .footer-list .title {
    margin-bottom: 15px;
    font-size: 19px !important;
  }
 
  .footer-list ul {    
    margin-left: 0;
    list-style: none;
    padding-inline-start: 0;

    li {
      list-style: none;
      list-style-position: inside;
      margin-bottom: 10px;
    }
  }

  .footer-list ul li a {
    font-size: 16px;
    text-decoration: none;
    color: $default;

    &:hover {
      color: $primary;
    }
  }


  
  .footer-social-link {
    display: flex;
    align-items: center;
  }
  
  .btn-email, .btn-twitter:hover {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
  }
  
  .btn-twitter, .btn-twitter:hover {
    color: #fff;
    background-color: $twitter;
    border-color: $twitter;
  }

  .btn-github, .btn-github:hover {
    color: #fff;
    background-color: $github;
    border-color: $github;
  }
  
  .btn:not(:last-child) {
    margin-right: .5rem;
  }

  a.btn-icon-only {    
    line-height: 2.5;
    font-size: .875rem;
    width: 2.375rem;
    height: 2.375rem;
    padding: 0;
  }
}
</style>
      