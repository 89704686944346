// Import just what we need

// import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faVectorSquare,
  faCoins,
  faEnvelope,
  faProjectDiagram,
  faChartLine,
  faExchangeAlt,
  faQrcode,
  faDatabase,
  faShieldAlt,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
library.add(faPhone);
library.add(faVectorSquare);
library.add(faCoins);
library.add(faEnvelope);
library.add(faProjectDiagram);
library.add(faChartLine);
library.add(faExchangeAlt);
library.add(faQrcode);
library.add(faDatabase);
library.add(faShieldAlt);
library.add(faStop);
import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons';
library.add(faGithub);
library.add(faTwitter);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

import { createApp } from 'vue'
import App from './App.vue'
import router from './router' 

createApp(App)
  .use(router)
  .mount('#app')
