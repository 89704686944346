<template>
  <nav
    class="navbar navbar-main navbar-transparent navbar-expand-lg navbar-light px-sm-5"
  >
    <a class="navbar-brand ml-5" href="/">
      <img
        src="/assets/logo.png"
        class="img-fluid"
        alt="Logo"
        width="50"
        height="50"
      />
      Coingrig
    </a>
    <button
      class="navbar-toggler"
      style="margin-right: 10px"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!-- <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">About</a>
            </li> -->
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </li> -->
      </ul>
      <ul class="d-flex navbar-nav mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="/">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="vision"  href="/vision">Vision</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="docs" rel="noopener" target="_blank" href="https://docs.coingrig.com">Docs</a>          
        </li>
        <!-- <li class="nav-item">
              <a class="nav-link" href="#">About</a>
            </li> -->
        <li class="nav-item">
            <a
            target="_blank"
            rel="noopener"
            href="https://twitter.com/coingrig"
            class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Follow us"
            aria-label="Follow on Twitter"
          >
            <i class="fab fa-twitter"></i>
          </a>  
          <a
            target="_blank"
            rel="noopener"
            href="https://github.com/coingrig"
            class="btn btn-neutral btn-icon-only btn-github btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Star on Github"
            aria-label="Star on Github"
          >
            <i class="fab fa-github"></i>
          </a>    
        </li>   
      </ul>
      <!-- <form class="d-flex mx-4">
            <button class="btn btn-outline btn-dark btn-primary btn-download" type="submit">
              Download
            </button>
          </form> -->
    </div>
  </nav>
</template>

<style lang="scss">

</style>
