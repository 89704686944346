<template>
  <div class="container-fluid">
    <div class="container py-4">

    <div class="justify-content-center row">
      <div class="col-lg-9 mb-5">
        <div class="text-center">
          <p color="heading" class="h2 display-6 fw-bold">
            Features
          </p>
        </div>
      </div>

      <div class="justify-content-center row" v-for="(row, index) in rows" :key="index">
        <div class="mb-4 col-lg-4 col-md-6" v-for="(feature, findex) in row" :key="findex">
          <div class="d-flex align-items-center features-box">
            <div class="d-flex justify-content-center align-items-center rounded-icon">
              <i :class="feature.icon"></i>
            </div>
            <p class="h5 mx-2">{{feature.text}}</p>
          </div>
        </div>        
      </div>
    </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AllFeatures',
  components: {},
  data: function () {
    return {
      rows: [
        [{
          'icon': 'fas fa-project-diagram',
          'text': 'Multi chain support',
        },{
          'icon': 'fas fa-coins',
          'text': 'Buy crypto with cards',
        },{
          'icon': 'fas fa-chart-line',
          'text': 'Check market informations',
        }],
        [{
          'icon': 'fas fa-exchange-alt',
          'text': 'Send and receive assets',
        },{
          'icon': 'fas fa-qrcode',
          'text': 'Wallet Connect support',
        },{
          'icon': 'fas fa-database',
          'text': 'Add custom ERC20 tokens',
        }],
        [{
          'icon': 'fas fa-shield-alt',
          'text': 'Private & Secure',
        },{
          'icon': 'fab fa-github',
          'text': 'Open source',
        }]
      ]
    }
  },
}
</script>

<style lang="scss">
.features-box {
  box-sizing: border-box;
  min-width: 0px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border: 1px solid rgba(65, 62, 101, 0.114);
  border-radius: 10px;  

  .rounded-icon {
    width: 3rem;
    height: 3rem;
    font-size: 20px;
    text-align: center;
    border-radius: 50%!important;
    background-color: #f5f4f4;  
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;    
  }
}
</style>