<template>
  <div class="position-relative">
    <section class="section-hero">
      <div class="shape-top-left">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjUwIiBoZWlnaHQ9IjEyNTAiIHZpZXdCb3g9IjAgMCAxMjUwIDEyNTAiPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iaDU2Z2EiIHgxPSI1NzUuODUiIHgyPSI5NTEuODQiIHkxPSI1NjkuMDciIHkyPSIxMTU0Ljk2IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjOGQ4ZGVjIiBzdG9wLW9wYWNpdHk9Ii4xNyIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzU0NTRkNCIgc3RvcC1vcGFjaXR5PSIwIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PGc+PGc+PHBhdGggZmlsbD0idXJsKCNoNTZnYSkiIGQ9Ik02MjUgMTI1MGMzNDUuMTc4IDAgNjI1LTI3OS44MjIgNjI1LTYyNVM5NzAuMTc4IDAgNjI1IDAgMCAyNzkuODIyIDAgNjI1czI3OS44MjIgNjI1IDYyNSA2MjV6Ii8+PC9nPjwvZz48L3N2Zz4="
          alt=""
          class="img-fluid"
        />
      </div>
      <slot></slot>
    </section>
  </div>
</template>

<script>
export default {
  name: "DiagonalSection",
  props: {
    msg: String,
  },
};
</script>

<style lang="scss">
.section-hero {
  margin: 0;
  padding-top: 13rem;
  padding-bottom: 13rem;
  position: relative;
  overflow: hidden;

  .shape-top-left {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -100;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 978px) {
  .section-hero {
    padding-bottom: 1rem;
  }
}
</style>
