<template>
  <div class="container-fluid">
    <div class="container py-4 px-lg-5">

    <div class="justify-content-center row">
      <div class="col-lg-9 mb-5">
        <div class="text-center">
          <p color="heading" class="h2 display-6 fw-bold">
            Supported chains
          </p>
          <p class="fs-5 fw-light">
            Popular cryptocurrencies and custom tokens (ERC20) are supported
          </p>
        </div>
      </div>

      <div class="justify-content-center row" v-for="(row, index) in rows" :key="index">
        <div class="mb-4 col-lg-4 col-md-6" v-for="(feature, findex) in row" :key="findex">
          <div class="d-flex align-items-center chains-box">
            <div class="d-flex justify-content-center align-items-center rounded-icon">
              <img :src="getImgUrl(feature.image)" :alt="feature.text" class="chain-icon" v-if="feature.image">
              <i :class="feature.icon" v-if="feature.icon"></i>
            </div>
            <p class="h5 mx-2">{{feature.text}}</p>
          </div>
        </div>        
      </div>
    </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Allchains',
  components: {},
  data: function () {
    return {
      rows: [
        [{
          'image': 'chains/bitcoin.webp',
          'text': 'Bitcoin',
        },{
          'image': 'chains/ethereum.webp',
          'text': 'Ethereum',
        },{
          'image': 'chains/binance-coin-logo.webp',
          'text': 'Binance Smart Chain',
        }],
        [{
          'image': 'chains/matic-token-icon.webp',
          'text': 'Polygon (MATIC)',
        },{
          'icon': 'fas fa-project-diagram',
          'text': 'More available soon',
        }]        
      ]
    }
  },
  methods: {
    getImgUrl(icon) {
      return '/assets/' + icon;
    }
  }
}
</script>

<style lang="scss">
.chains-box {
  box-sizing: border-box;
  min-width: 0px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border: 1px solid rgba(65, 62, 101, 0.114);
  border-radius: 10px;  

  .chain-icon {
    height: 30px;
  }

  .rounded-icon {
    width: 3rem;
    height: 3rem;
    text-align: center;
    border-radius: 50%!important;
    background-color: #f5f4f4;  
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>