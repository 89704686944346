<template>
  <ContentHero>
    <div class="row">
      <div class="col-lg-12">
        <h1 class="display-5">
          Our vision
        </h1>
      </div>
    </div>
  </ContentHero>
  
  <div class="container px-lg-5">
  <div class="container my-4 py-4">
    <p class="h4 fw-light">We are looking towards a future where almost all of what we interact with in our real-life will be able to be tokenized and have a digital equivalent in the metaverse. Until then we are building the tools that we can use right now to make the next steps into better integrating creators of value with the crypto world.</p>
  </div>

  <div class="container text-center my-5 pt-4">
    <h2 class="h3 display-6 fw-bold">What's next?</h2>
  </div>


<div class="container-fluid my-5">
    <div class="container py-5 my-5">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-lg-6 text-center">
          <picture >
              <source type="image/webp" alt="Create your tokens in-app" srcset="../assets/vision_01.webp, ../assets/vision_01_2x.webp 2x" width="636" height="489">
              <source type="image/jpeg" alt="Mobile app features:Buy, Send and Receive Crypto" srcset="../assets/vision_01.jpg, ../assets/vision_01_2x.jpg 2x" width="636" height="489">
              <img src="../assets/vision_01.jpg" alt="Create your tokens in-app" class="img-fluid" >
          </picture>
        </div>
        <div class="col-lg-6 my-auto">
          <h2 class="mb-3">
            <span class="fs-4"><i class="fa fa-stop info-bullet-black"></i></span> Create and trade NFTs in-app.
          </h2>
          <p class="fs-5 fw-light">
            As a content creator we want you to have the tools to transform your creations quickly into assets that can be sold and traded through crypto. This way you can focus on creating new ways to engage with your audience by creating public NFTs or privately traded encrypted NFTs without a hassle.
          </p>          
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid my-5">
      <div class="row">
        <div class="col-lg-6 my-auto">
          <h2 class="mb-3">
            <span class="fs-4"><i class="fa fa-stop info-bullet-orange"></i></span> Create your tokens in-app
          </h2>
          <p class="fs-5 fw-light">
            Create your custom crypto token to use as you see fit! Share it with your friends, use it in your team or even explore new ways to engage with your clients, partners or investors. You’ll have the ability to create an utility token directly from the wallet. You may choose then to trade it freely or for a certain value.
          </p>
        </div>
        <div class="col-lg-6 text-center">
          <picture >
              <source type="image/webp" alt="Create your tokens in-app" srcset="../assets/vision_02.webp, ../assets/vision_02_2x.webp 2x" width="636" height="318">
              <source type="image/jpeg" alt="Create your tokens in-app" srcset="../assets/vision_02.jpg, ../assets/vision_02_2x.jpg 2x" width="636" height="318">
              <img src="../assets/vision_02.jpg" alt="Create your tokens in-app" class="img-fluid">
          </picture>
        </div>        
      </div>
  </div>

  <div class="container-fluid my-5">
    <div class="container py-5 my-5">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-lg-6 text-center">
          <picture >
              <source type="image/webp" alt="1-Click investing" srcset="../assets/vision_03.webp, ../assets/vision_03_2x.webp 2x" width="640" height="427">
              <source type="image/jpeg" alt="1-Click investing" srcset="../assets/vision_03.jpg, ../assets/vision_03_2x.jpg 2x" width="640" height="427">
              <img src="../assets/vision_03.jpg" alt="1-Click investing" class="img-fluid"  width="640" height="427">
          </picture>
        </div>
        <div class="col-lg-6 my-auto">
          <h2 class="mb-3">
            <span class="fs-4"><i class="fa fa-stop info-bullet-gray"></i></span> 1-Click investing
          </h2>
          <p class="fs-5 fw-light">
            Hop-on into the crypto world by opting to invest easily into ready-made index style funds. Smart contracts will offer us the option to create sets of desirable crypto assets that can be purchased through a single order instead of individually picking and trading crypto assets.
          </p>
        </div>
      </div>
    </div>
  </div>


  <div class="container text-center">
    <p class="h3 mb-4">Check out our immediate roadmap</p>
    <a class="btn btn-primary" aria-current="roadmap" rel="noopener" target="_blank" href="https://github.com/orgs/coingrig/projects/1">
      Available on Github
    </a>
  </div>
  </div>
</template>

<script>
import ContentHero from "@/components/ContentHero";

export default {
  name: 'Vision',
  components: {
    ContentHero,
  },
}
</script>

<style lang="scss">
</style>
