import { createWebHistory, createRouter } from "vue-router";
import Landing from "./views/Landing.vue";
import PrivacyPolicy from "./views/PrivacyPolicy.vue";
import TermsAndConditions from "./views/TermsAndConditions.vue";
import Credits from "./views/Credits.vue";
import Vision from "./views/Vision.vue";

import HeaderContent from "@/layout/HeaderContent.vue";
import HeaderHome from '@/layout/Header.vue'
import Footer from '@/layout/Footer.vue'

let setDefaultComponents = (component) => {
  return Object.assign({}, {
    default: component,
    AppHeader: HeaderHome,
    AppFooter: Footer,
  });
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Landing,
    components: setDefaultComponents(Landing)
  },
  {
    path: "/vision",
    name: "Vision",
    components: {
      default: Vision,
      AppHeader: HeaderContent,
      AppFooter: Footer,
    }
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    components: {
      default: PrivacyPolicy,
      AppHeader: HeaderContent,
      AppFooter: Footer,
    }
  },
  {
    path: "/terms",
    name: "Terms and Conditions",
    components: {
      default: TermsAndConditions,
      AppHeader: HeaderContent,
      AppFooter: Footer,
    }
  },
  {
    path: "/credits",
    name: "Credits",
    components: {
      default: Credits,
      AppHeader: HeaderContent,
      AppFooter: Footer,
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

