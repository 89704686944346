<template>
  <div id="app">
    <router-view class="view app-header" name="AppHeader"></router-view>
    <main>
      <router-view />
    </main>
    <router-view class="view app-footer" name="AppFooter"></router-view>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss">
.section-hero {
    margin: 0;
    padding-top: 3rem !important;
    padding-bottom: 2rem !important;
    position: relative;
    overflow: hidden;
}
.title{
  font-size: 5rem !important;
}


#navbarSupportedContent.navbar-collapse.collapse.show {
  background-color: #fff !important;
  padding-right: 10px !important;

  .nav-link {
    padding-right: 10px !important;
  }
  
  border-bottom: solid #cecece 1px !important;
  border-top: solid #cecece 1px !important;
}
</style>