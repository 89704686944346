<template>
  <div class="container container-lg">
    <div class="row footer-upper-top-item">
      <div class="col-lg-3 col-6 text-center text-sm-start">
        <a aria-current="page" href="/" class="router-link-active router-link-exact-active footer-logo">
          <img
            src="/assets/logo.png"
            class="img-fluid"
            alt="Logo"
            width="50"
            height="50"
          />
          Coingrig
        </a>
      </div>
      <div class="col-lg-3 col-6 footer-social-link">
      </div>
      <div class="col-lg-6 col-12 my-sm-0 my-5">
        <div class="text-lg-end text-center">
          <a role="button" href="https://apps.apple.com/us/app/coingrig-crypto-btc-wallet/id1583464451">
            <img src="../../assets/download_apple.png"
              height="45" alt="Apple store"
            />
          </a>
          &nbsp;&nbsp;&nbsp;
          <a role="button" href="https://play.google.com/store/apps/details?id=com.coingrig">
            <img  src="../../assets/download_play.png"
              height="45" alt="Google store"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-footer-top",  
};
</script>

<style lang="scss">
.footer-upper-top-item {
  padding-bottom: 40px;
  position: relative;

  &::before {    
    background-color: #cecece;
    height: 1px;
    width: 100%;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .footer-logo {
    font-size: 1.25rem;
    color: $default;
    text-decoration: none;
  }

}
</style>
      